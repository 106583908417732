.InfiniteScrollWrapper {
	display: flex;
	width: 100%;
	gap: 10px;
	flex-wrap: wrap;
}

.userOptionsMenuPaper {
	max-height: 300px !important;
	max-width: 300px;
}

.searchSelectInput {
	border-bottom: "1px solid #edebeb";
	margin-top: "10px";
	background: #FFF;
	padding: 10px 0;
}

.labelColor {
	color: #515151;
}

.textColor {
	color: #393d40;
	font-weight: bold !important;
}

.status {
	margin-right: 5px;
	padding: 10px 15px;
	border-radius: 15px
}

.Pending {
	color: #828282;
	background: #f6f6f6;
}

.Completed {
	color: #FFF;
	background: #019B07;
}

.title {
	font-size: 1.25rem;
	font-family: helvetica, sans-serif !important;
	font-weight: bold !important;
	line-height: 1.6;
}
@media screen and (max-width:600px) {
	.title {
		font-size: 1rem !important;
	}

.labelColor {
	font-size: 0.9rem;
}
}