
.react-router-modal__modal_custom {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
@media screen and (max-width:1200px) {
	.react-router-modal__modal_custom {
		position: unset;
		top: unset;
		left: unset;
		transform: unset;
		-webkit-transform:unset;
	}
}

body {
	font-family: roboto, sans-serif !important ;
	max-width: 100vw !important;
	box-sizing: border-box !important;
	overflow: auto ;
	padding: 0 !important;
}

/* patch chrome auto complete color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-internal-autofill-selected {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}


a {
	color: inherit;
	text-decoration: none;
	/* no underline */
}

/* common css clasess start*/

.regular {
	font-weight: 400 !important;
}

.semibold {
	font-weight: 500 !important;
}

.bold {
	font-weight: bold !important;
}

.extrabold {
	font-weight: bolder;
}

/* margin  */
.m0 {
	margin: 0 !important;
}

.ml0 {
	margin-left: 0px !important;
}

.mr0 {
	margin-right: 0px !important;
}

.m10 {
	margin: 10px !important;
}

.ml5 {
	margin-left: 5px !important;
}

.ml10 {
	margin-left: 10px !important;
}

.mr5 {
	margin-right: 5px !important;
}

.mr10 {
	margin-right: 10px !important;
}

.mb16 {
	margin-bottom: 16px !important;
}

.mb20 {
	margin-bottom: 20px !important;
}

/* padding */
.p0 {
	padding: 0 !important;
}

.p13 {
	padding: 13px !important;
}

.p16 {
	padding: 16px !important;
}

.pt8 {
	padding-top: 8px !important;
}

.pv12 {
	padding-left: 12px;
	padding-right: 12px;
}

.pv27 {
	padding-left: 27px;
	padding-right: 27px;
}

.pl5 {
	padding-left: 5px !important;
}

.font10 {
	font-size: 10px !important;
}

.font11 {
	font-size: 11px !important;
}

.font12 {
	font-size: 12px !important;
}

.font13 {
	font-size: 13px !important;
}

.font14 {
	font-size: 14px !important;
}

.font15 {
	font-size: 15px !important;
}

.font16 {
	font-size: 16px !important;
}

.font17 {
	font-size: 17px !important;
}

/* common css clasess end*/

.formRow{
    margin-bottom: 12px !important;
}

.formLabel{
    font-size: 1.0rem !important;
    font-weight: 500;
    line-height: 1.345rem;
    color: #575757 !important;
    margin-bottom: 5px !important;
}