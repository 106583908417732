.listAvatar {
    width: 30px !important;
    height: 30px !important;
}

.listLeftContainer {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
    gap: "15px"
}

.listLeftContainer span {
    margin-left: 10px;
}

.linkInput {
    padding: 0px;
}

.userSelect {
    padding: 0px;
}

.MuiOutlinedInput-input {
    padding: 10.5px 10px !important;
}

.userSelect .MuiInputBase-root .MuiSelect-root {
    padding: 10.5px 10px !important;
}

.shareButtons {
    -webkit-justify-content: flex-end !important;
    align-items: flex-end !important;
    -webkit-align-items: flex-end !important;
    display: flex !important;
    margin-top: 150px !important;
}

@media screen and (max-width:400px) {
    .shareButtons{
        margin-top: 120px !important;
        flex-direction: column-reverse;
    }
}