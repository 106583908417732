.userOptionsMenuPaper {
	max-height: 300px !important;
	max-width: 300px;
}

.searchSelectInput {
	border-bottom: "1px solid #edebeb"; 
	margin-top: "10px";
	background: #FFF;
	padding: 10px 0;
}