.wrapper {
	position: fixed; 
	bottom: 0; 
	right: 0;
	display: flex;
	justify-content: flex-end;
}

.closedChatCard {
	padding: 10px 0px 10px 10px;
	display: flex; 
	justify-content: space-between;
	width: 300px;
	cursor: pointer;
}

.chatHeader {
	align-items: center;
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: space-between;
}

.collapsibleAnimation{
	transition: all 0.5s;
}