

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFF;
  border-radius: 40px;
  border: 1px solid rgb(0, 155, 33);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: rgb(0, 155, 33);
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.sliderDisabled {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFF;
  border-radius: 40px;
  border: 1px solid rgb(93, 94, 93);
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.sliderDisabled:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: rgb(93, 94, 93);
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

input:checked + .slider {
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -moz-transform: translateX(22px);
  transform: translateX(22px);
}

input:checked + .sliderDisabled:before {
  -webkit-transform: translateX(22px);
  -moz-transform: translateX(22px);
  transform: translateX(22px);
}

input:focus + .slider {
}
