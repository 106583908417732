.rowStyleClass input, .rowElStyleClass input{
	padding: 13px 20px !important;
}
.rowTextStyleClass div {
    padding: 13px 20px 20px;  
}
.rowPriceStyleClass input{
    padding: 13px 5px !important;
}
.rowElStyleClass {
    margin-bottom: 20px;
}
.labelStyleClass {
	font-size: 13px !important;
    /* font-weight: bold !important; */
}
.modalTopCls div.MuiPaper-root {
    padding-left: 0px;
    padding-right: 0px;
}