.chipAvatar {
    width: 20px !important;
    height: 21px !important;
}
.chipText{
    font-size: 0.81rem !important;
}

.chipAvatar img {
    object-fit: contain !important;
}

.txtColor {
    color: #686868 !important;
}

.mr3 {
    margin-right: 3px;
}

.mr16 {
	margin-right: 16px !important;
}



.userName{
    font-weight: bold !important;
    font-family: Roboto !important;
    margin-bottom: 4px !important;
    font-size: 1.2rem !important;
    max-width: 30ch;
    overflow: hidden;
    text-overflow: ellipsis !important;
    display: block !important;
    text-transform: capitalize !important;
}
@media screen and (max-width:550px) {
    .userName{
        text-align: center;
    }
}
@media screen and (min-width:768px) and (max-width:1200px) {
    .userName{
        text-align: center;
    }
}