.listAvatar {
    width: 30px !important;
    height: 30px !important;
}

.listLeftContainer {
    display: flex;
    flex-direction: row-reverse;
    width: fit-content;
    justify-content: space-between;
    align-items: center;
}

.listLeftContainer span {
    margin-left: 10px;
}

.linkInput {
    padding: 0px;
}
.inputCss{
    /* dont remove this class */
    padding-left: 6px;
}

.inputCss div input {
      padding-left: 34px !important;  
}
