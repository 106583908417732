.InfiniteScrollWrapper {
	display: flex;
	width: 100%;
	gap: 10px 20px;
	flex-wrap: wrap;
}
.userOptionsMenuPaper {
	max-height: 300px !important;
	max-width: 300px;
}
.searchSelectInput {
	border-bottom: "1px solid #edebeb"; 
	margin-top: "10px";
	background: #FFF;
	padding: 10px 0;
}
.ccheader {
	display: flex; 
    justify-content: space-between;
     overflow: hidden; 
}
.ccAction {
	display: flex;
	width: 940px;
	padding-left: 10px;
	align-items: center;
	justify-content: flex-end;
	gap: 10px; 
	flex-wrap: wrap;
}
.parentCard{
	display: flex;
	 width: calc(50% - 10px);
}
.DetailCard{
	display: flex;
	justify-content: center;
	/* padding: 0px 15px; */
}
@media screen and (min-width:769px) and (max-width:850px) {
	.ccAction{
		justify-content: center !important;
	}
	.langDiv{
		justify-content: center !important;
	}
}
@media screen and (min-width:900px) and (max-width:1030px) {
	.ccAction{
		justify-content: center !important;
	}
}
@media screen and ( min-width:768px)and (max-width: 1200px){
	.parentCard{
		width: 100%;
	}
}
@media screen and (max-width: 768px){
	.DetailCard{
		padding: 0px 15px;
	}
}
@media screen and (max-width: 768px){
	.InfiniteScrollWrapper{
		padding: 15px;
		width: 95.6%;
	}
}
@media screen and (max-width:500px) {
	.parentCard{
		width: 100%;
	}
	.InfiniteScrollWrapper{
		padding: 10px;
		width: 95.6%;
	}
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
	.ccheader {
		display: block;
	}
	.ccAction {
		justify-content: flex-start;
		margin-top: 15px;
		margin-left: 80px;
	}
}
@media screen and (max-width: 768px) {
	.ccheader {
		display: flex;
		flex-direction: column;
	}
	.ccAction {
		justify-content: flex-start;
		margin-top: 15px;
		flex-wrap: wrap;
	}
}
@media screen and (max-width: 450px) {
	.ccheader {
		display: flex;
		flex-direction: column;
	}
	.ccAction {
		justify-content: center;
		margin-top: 15px;
		flex-wrap: wrap;
		width: 100%;
		padding-left: 0px;
	}
}
@media screen and (min-width:768px) and (max-width: 1200px) {
	.ccheader {
		display: flex;
		flex-direction: column;
	}
	.ccAction {
		justify-content: flex-start;
		margin-top: 15px;
		flex-wrap: wrap;
		width: 100%;
		padding-left: 0px;
	}
}
@media screen and (max-width:400px){
	.ccAction{
		padding-left: 0px !important;
	}
	.langDiv{
		justify-content: center !important;
	}
	.profilePic{
		width:50px !important;
		height:50px !important;
	}
	.cardName{
		font-size: 16px !important;	
	}
	.boldHead{
		font-size: 15px !important;
	}
	.boldValue{
		font-size: 15px !important;
	}
}
@media screen and (max-width:420px){
	.InfiniteScrollWrapper{
		padding: 10px;
		width: 94.6%;
	}
}
@media screen and ( min-width:500px)and (max-width: 650px){
	.profilePic{
		width:50px !important;
		height:50px !important;
	}
	.cardName{
		font-size: 16px !important;	
	}
	.boldHead{
		font-size: 15px !important;
	}
	.boldValue{
		font-size: 15px !important;
	}
}
@media screen and ( min-width:768px)and (max-width: 850px){
	.profilePic{
		width:50px !important;
		height:50px !important;
	}
	.cardName{
		font-size: 16px !important;	
	}
	.boldHead{
		font-size: 15px !important;
	}
	.boldValue{
		font-size: 15px !important;
	}
}